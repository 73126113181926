@import core/var

body
    +bgj(t1,center top)
    background-size: 100%
// ------------------------------
// (*￣︿￣) 登录
// ------------------------------
.l-main,.r-main 
    margin: 50px auto 100px auto
    border: 1px solid $line
    box-shadow: 0 0 5px rgba(0,0,0,.1)
    padding: 80px 0
    background: #fff
    form
        padding-left: 100px
        width: 560px
        border-right: 1px solid $line
        .line
            margin-top: 20px
            input
                border: 1px solid $line
            .user
                +bg(user,10px)
                padding-left: 35px !important
            input[type=password]
                +bg(pass,10px)
                padding-left: 35px !important
            input[type=text],input[type=password],input[type=submit]
                width: 360px
                height: 40px
                line-height: 38px
                outline: none
                padding-left: 10px
            input[type=checkbox]
                // vertical-align: middle
            input[type=submit]
                padding-left: 0
                background: $main
                border: 0
                color: #fff
                font-size: 16px
                cursor: pointer
                +br(3px)
                &:hover
                    background-color: darken($main,5%)
            
            i
                font-style: normal
                color: #999
            b
                font-size: 24px
                font-weight: normal
            a
                color: #4599D8
                padding-left: 88px
                font-size: 14px
        .line-a 
            a
                padding-left: 0
        .line2
            a
                float: left
                width: 50px
                height: 49px
                +bg(login,center)
                padding-left: 0
            a.qq
                background-position: 0 0
                margin-left: 50px
            a.weibo
                background-position: -60px 0
                margin-left: 10px
            a.zhifu
                background-position: -120px 0
                margin-left: 10px
    .area-r
        padding-left: 100px
        dt
            font-size: 24px
        dd
            margin-top: 15px
            font-size: 16px
            a
            
                display: block
                width: 335px
                height: 43px
                background: #5BC0DE
                -webkit-border-radius: 3px
                -moz-border-radius: 3px
                -ms-border-radius: 3px
                -o-border-radius: 3px
                border-radius: 3px
                color: #fff
                text-align: center
                line-height: 43px
                &:hover
                    background-color: darken(#5BC0DE,5%)




// ------------------------------
// (*￣︿￣) 注册
// ------------------------------

.r-main
    padding-left: 80px
    form
        padding-left: 0
        h2
            font-size: 24px
        .line
            position: relative
            padding-left: 90px
            label
                font-size: 16px
                width: 80px
                line-height: 40px
                text-align: right
                position: absolute
                left: 0
            #yan
                width: 100px
                vertical-align: top
            input[type=checkbox]
                margin-left: 0
                vertical-align: middle
        .yz
            img
                width: 125px
                height: 40px
                // vertical-align: middle
    .area-r
        dd
            color: #888
        .a-r-a a
            
            display: block
            width: 335px
            height: 43px
            background: #5BC0DE
            -webkit-border-radius: 3px
            -moz-border-radius: 3px
            -ms-border-radius: 3px
            -o-border-radius: 3px
            border-radius: 3px
            color: #fff
            text-align: center
            line-height: 43px
            &:hover
                background-color: darken(#5BC0DE,5%)
        .line2
            margin-top: 15px
            a
                float: left
                width: 50px
                height: 49px
                +bg(login,center)
                padding-left: 0
            a.qq
                background-position: 0 0
            a.weibo
                background-position: -60px 0
                margin-left: 10px



.join-title
    text-align: center
    margin-top: 60px
    h2.login
        height: 62px
        line-height: 0
        font-size: 0
        +bg(mlogin, center top)
    h2.reg
        height: 62px
        line-height: 0
        font-size: 0
        +bgj(mreg, center top)